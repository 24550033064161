.p-multiselect-items.p-component {
  padding-top: 1rem !important;
  padding-left: 1rem !important;
}

.p-multiselect-items {
  display: flex !important;
  flex-direction: column !important;
  gap: 1rem !important;
}

.p-multiselect-item:hover  {
  background: #fff !important;
  color: #4b5563 !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus  {
  background: #fff !important;
  color: #4b5563 !important;
}

.p-multiselect-checkbox {
  margin-right: 0.5rem !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: #fff !important;
  color: #4b5563 !important;
}

.p-multiselect-trigger {
  display: none !important;
}

.p-checkbox.p-highlight .p-checkbox-box {
  background-color: #0049B6 !important;
  background: #0049B6 !important;
  border-color: #0049B6 !important;
}

.p-checkbox-box {
  border-color: #0049B6 !important;
}
